import React, { useEffect, useState } from "react";
import Checkbox from "./checkbox";

export default function CheckboxGroup({
  name,
  options = [],
  onChange,
  defaultValue = [],
}) {
  const [val, setVal] = useState(genOptions(defaultValue));

  useEffect(() => {
    if (defaultValue.length) {
      onChange(genOptions(defaultValue));
    }
  }, [defaultValue]);

  function handleChange({ target: t }) {
    const checkboxVal = t.input ? t.inputVal : t.value;
    const finded = val.find((e) => e.label === t.label);
    let v = val;
    if (!finded && t.checked) {
      v = [...val, { label: t.label, value: checkboxVal }];
    } else if (t.input) {
      if (t.checked) finded.value = checkboxVal;
      else v = val.filter((e) => e.label !== t.label);
    } else {
      v = val.filter((e) => e.value !== checkboxVal);
    }

    setVal(v);
    onChange(v);
  }

  function genOptions(opts) {
    return opts.map((e) => {
      if (typeof e === "string") {
        return {
          label: e,
          value: e,
        };
      }

      return e;
    });
  }

  return (
    <section>
      {genOptions(options).map((e) => {
        const current = defaultValue.find((item) => item.label === e.label);
        return (
          <Checkbox
            key={e.value.toString()}
            label={e.label}
            value={e.value}
            input={e.input}
            name={name}
            defaultChecked={defaultValue.includes(e.value) || current?.input}
            current={current}
            styleMap={{ marginRight: "45px" }}
            onChange={handleChange}
          />
        );
      })}
    </section>
  );
}
