import React, { useState, useEffect } from "react";
import { Link, replace } from "gatsby";
import Form from "rc-field-form";
import Message from "rc-message";
import axios from "../../../utils/request";
import ErrorField from "../../../components/error-field";
import CheckboxGroup from "../../../components/checkbox-group";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import style from "./contract.module.css";

export default ({ location }) => {
  const [contract, setContract] = useState({});

  useEffect(() => {
    async function getList() {
      const { data } = await axios({
        method: "get",
        url: "/designer/contract/list",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      let id;
      if (data) id = data[0].contractId;
      const { data: contractData } = await axios({
        method: "get",
        url: "/designer/contract",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        params: {
          contractId: id,
        },
      });
      if (contractData?.contractId) setContract(contractData);
    }

    getList();
  }, []);

  return (
    <div>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.publishHeader}>
        <Link to="/" className={style.publishIcon}>
          产品出版社
        </Link>
        <Link to="" className={style.login}>
          退出
        </Link>
      </div>
      <div className={style.contractBox}>
        <h2>
          合同确认<span>（请及时确认合同，如有异议请直接联系BD专员）</span>
        </h2>
        <div className={style.contractWrap}>
          <h3>ICY X {contract?.username?.toUpperCase()}</h3>
          <ul className={style.contractList}>
            <li>
              合同起止日期 {contract?.startTime?.split(" ")[0]} —
              {contract?.endTime?.split(" ")[0]}
            </li>
            <li>结款周期：{contract?.billCycle}天</li>
            <li>
              销售分成：
              {contract?.rate ? contract?.rate + "%" : ""}
            </li>
            <li>分成周期：{contract?.commissionCycle}天</li>
            {
              contract?.minimumRate !== 0 && <li>保底版费：{contract?.minimumRate}元</li>
            }
          </ul>
          {/* <a href={contract?.url} className={style.contractMore}>查看完整合同</a> */}
        </div>
        <Form
          onFinish={async () => {
            const { data } = await axios({
              method: "put",
              url: "/designer/contract/confirm",
              headers: { "Content-Type": "application/json;charset=UTF-8" },
              data: {
                contractId: contract.contractId,
              },
            });
            if (data.success) {
              replace("/designerPress/jointInfo");
            } else {
              Message.warning({
                content: data.msg,
              });
            }
          }}
          onFinishFailed={() => {
            //console.warn(e);
          }}
        >
          <button className={style.buttonStyle}>确认</button>
          <div className={style.checkWrap}>
            <ErrorField
              rules={[
                {
                  validator(rule, value, callback) {
                    if (!Array.isArray(value)) {
                      callback("请勾选商务条款");
                    } else {
                      if (value[0] === undefined) {
                        callback("请勾选商务条款");
                      }
                    }
                    callback();
                  },
                  validateTrigger: "onSubmit",
                },
              ]}
              name="comfirm"
            >
              <CheckboxGroup options={["我已详细阅读，并同意以上商务条款"]} />
            </ErrorField>
          </div>
        </Form>
      </div>
    </div>
  );
};
